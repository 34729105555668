// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorIcon } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const fill = colorIcon;

const displayName = "DoctorsOnDemandLogo";

const HEIGHT = 64;

function DoctorsOnDemandLogo({ id, ...props }) {
  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  // SVG editor for rescaling the viewbox
  //  https://pixelied.com/editor

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 1166 624`}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-DoctorsOnDemandLogo-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-DoctorsOnDemandLogo-title" />
      <g
        id="icon-DoctorsOnDemandLogo-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g>
          <path
            fill={fill}
            d="m205.6 323.4c0 35.2-24.3 59.3-62.2 59.3h-44.5v-118.7h44.5c37.9 0 62.2 24 62.2 59.4zm-64.1 34.3c21.8 0 34.7-12.5 34.7-34.3 0-21.9-12.9-34.7-34.7-34.7h-13.7v69z"
          />
          <path
            fill={fill}
            d="m264.5 384.1c-27.7 0-48.7-18.6-48.7-48.6 0-29.9 21.5-48.5 49.1-48.5 27.7 0 49 18.6 49 48.5 0 30-21.6 48.6-49.4 48.6zm0-25c10.3 0 20-7.7 20-23.6 0-16-9.5-23.5-19.6-23.5-10.5 0-19.7 7.5-19.7 23.5 0 15.9 8.8 23.6 19.3 23.6z"
          />
          <path
            fill={fill}
            d="m369.8 287c23.7 0 40.4 13 45.3 35.5h-30.8c-2.3-6.6-7.1-10.8-15-10.8-10.3 0-17.4 8.1-17.4 23.8 0 15.8 7.1 23.9 17.4 23.9 7.9 0 12.5-3.9 15-10.8h30.8c-4.9 21.8-21.6 35.5-45.3 35.5-27.8 0-47.4-18.6-47.4-48.6 0-29.7 19.6-48.5 47.4-48.5z"
          />
          <path
            fill={fill}
            d="m433.5 312.4h-11.5v-24.1h11.5v-23h28.9v23h19v24.1h-19v37.9c0 5.5 2.4 7.9 8.8 7.9h10.3v24.5h-14.7c-19.6 0-33.3-8.2-33.3-32.8z"
          />
          <path
            fill={fill}
            d="m538.4 384.1c-27.7 0-48.7-18.6-48.7-48.6 0-29.9 21.5-48.5 49.1-48.5 27.7 0 49 18.6 49 48.5 0 30-21.6 48.6-49.4 48.6zm0-25c10.4 0 20-7.7 20-23.6 0-16-9.5-23.5-19.6-23.5-10.5 0-19.7 7.5-19.7 23.5 0 15.9 8.9 23.6 19.3 23.6z"
          />
          <path
            fill={fill}
            d="m630.7 382.7h-29v-94.4h29v15.8c6.6-10 16.9-16.8 29.6-16.8v30.6h-8c-13.7 0-21.6 4.8-21.6 21z"
          />
          <path
            fill={fill}
            d="m710.1 384.1c-25.2 0-42.2-14-43.5-32.3h28.6c0.7 6.6 6.6 11 14.5 11 7.5 0 11.4-3.4 11.4-7.6 0-15.3-51.5-4.3-51.5-38.9 0-16.1 13.7-29.3 38.4-29.3 24.4 0 37.9 13.5 39.8 32.1h-26.7c-0.9-6.4-5.8-10.6-13.9-10.6-6.8 0-10.5 2.7-10.5 7.2 0 15.1 51.1 4.4 51.6 39.6 0 16.5-14.5 28.8-38.2 28.8z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="m147.6 525.1c-27.8 0-48.7-18.6-48.7-48.5 0-30 21.5-48.6 49-48.6 27.8 0 49.1 18.6 49.1 48.6 0 29.9-21.7 48.5-49.4 48.5zm0-25c10.3 0 20-7.6 20-23.5 0-16.1-9.5-23.5-19.7-23.5-10.5 0-19.6 7.4-19.6 23.5 0 15.9 8.8 23.5 19.3 23.5z"
          />
          <path
            fill={fill}
            d="m276 472.5c0-12.9-7.1-20-18.1-20-11 0-18.1 7.1-18.1 20v51.3h-28.9v-94.4h28.9v12.5c5.8-8 15.9-13.6 28.6-13.6 21.9 0 36.4 14.9 36.4 40.3v55.2h-28.8z"
          />
          <path
            fill={fill}
            d="m467.6 464.4c0 35.2-24.4 59.4-62.3 59.4h-44.5v-118.8h44.5c37.9 0 62.3 24 62.3 59.4zm-64.1 34.3c21.8 0 34.7-12.5 34.7-34.3 0-21.8-12.9-34.7-34.7-34.7h-13.7v69z"
          />
          <path
            fill={fill}
            d="m525.5 525.1c-27.8 0-47.7-18.6-47.7-48.5 0-30 19.6-48.6 47.7-48.6 27.6 0 47 18.3 47 47 0 2.8-0.2 5.6-0.5 8.5h-65.5c1 12.4 8.7 18.1 18 18.1 8.1 0 12.7-4 15-9.1h30.8c-4.5 18.4-21.3 32.6-44.8 32.6zm-18.8-57.7h36.2c0-10.3-8.1-16.2-17.8-16.2-9.4 0-16.7 5.7-18.4 16.2z"
          />
          <path
            fill={fill}
            d="m716.7 472.5c0-12.3-7.1-19.1-18.1-19.1-11 0-18.1 6.8-18.1 19.1v51.3h-28.7v-51.3c0-12.3-7.1-19.1-18.1-19.1-11 0-18.1 6.8-18.1 19.1v51.3h-29v-94.4h29v11.8c5.5-7.6 15.2-12.9 27.5-12.9 14.3 0 25.8 6.3 32.2 17.6 6.3-9.9 18.1-17.6 31.8-17.6 23.4 0 38.4 14.9 38.4 40.3v55.2h-28.8z"
          />
          <path
            fill={fill}
            d="m800.9 428c14.1 0 24 6.4 29.1 14.7v-13.3h28.9v94.4h-28.9v-13.4c-5.2 8.3-15.2 14.7-29.3 14.7-23 0-41.4-18.9-41.4-48.7 0-29.8 18.4-48.4 41.6-48.4zm8.5 25.2c-10.9 0-20.7 8.1-20.7 23.2 0 15.1 9.8 23.5 20.7 23.5 11 0 20.6-8.3 20.6-23.3 0-15.1-9.6-23.4-20.6-23.4z"
          />
          <path
            fill={fill}
            d="m943.5 472.5c0-12.9-7.1-20-18.1-20-11 0-18.1 7.1-18.1 20v51.3h-28.9v-94.4h28.9v12.5c5.8-8 15.9-13.6 28.6-13.6 21.8 0 36.4 14.9 36.4 40.3v55.2h-28.8z"
          />
          <path
            fill={fill}
            d="m1027.3 428c12.5 0 23.4 5.8 29.1 14.4v-43.8h28.9v125.2h-28.9v-13.6c-5.2 8.7-15.2 14.9-29.1 14.9-23.2 0-41.6-18.9-41.6-48.7 0-29.8 18.4-48.4 41.6-48.4zm8.5 25.2c-10.9 0-20.7 8.1-20.7 23.2 0 15.1 9.8 23.5 20.7 23.5 11 0 20.6-8.3 20.6-23.3 0-15.1-9.6-23.4-20.6-23.4z"
          />
        </g>
        <g>
          <path
            fill={fill}
            d="m1085.3 206.7l-0.1-0.1c0.1 0.6 0.1 1.2 0.1 1.8v108.1c0 37.3-30.6 67.6-68.4 67.6h-119.7c-37.8 0-68.4-30.3-68.4-67.6v-108.1c0-37.3 30.6-67.5 68.4-67.5h108.9l79.2-52.9zm-31.1-60.1l-37 25.1q-0.2-0.1-0.3-0.1h-119.7c-20.6 0-37.3 16.6-37.3 36.9v108.1c0 20.3 16.7 36.8 37.3 36.8h119.7c20.6 0 37.3-16.5 37.3-36.8 0 0 0-170 0-170z"
          />
          <path
            fill={fill}
            d="m939.1 333.7v-72.4c0-9.3 7.6-16.8 16.9-16.8 9.3 0 16.8 7.5 16.8 16.8v72.4z"
          />
          <path
            fill={fill}
            d="m1033.9 278.2h-77.9c-9.3 0-16.9-7.6-16.9-16.9 0-9.3 7.6-16.8 16.9-16.8h77.9z"
          />
        </g>
        <g>
          <g>
            <path fill={fill} d="m109.2 206.8h-10.3v-40.7h10.3z" />
            <path
              fill={fill}
              d="m128.6 184.2v22.6h-10.3v-40.7h8l16.2 22.3v-22.3h10.3v40.7h-7.9z"
            />
            <path
              fill={fill}
              d="m176.2 206.8v-40.7h18.6c8.9 0 13.7 6.4 13.7 13.8 0 7.4-4.9 13.4-13.7 13.4h-8.3v13.5zm17.4-21.7c2.8 0 4.6-2.3 4.6-5.2 0-3-1.8-5.3-4.6-5.3h-7.1v10.5z"
            />
            <path
              fill={fill}
              d="m207.8 206.8l15.8-40.7h9.6l16 40.7h-10.9l-2.7-7.2h-14.5l-2.7 7.2zm15.6-15.5h9.9l-4.8-14.3z"
            />
            <path
              fill={fill}
              d="m277.6 192c0.9 4.1 2.7 6.9 6 6.9 1.4 0 3.1-0.6 4.3-1.6l3.4 6.8c-2.8 2.1-5.5 3.4-9.7 3.4-10 0-13-10.6-13.6-14.8h-3.9v14.1h-10.3v-40.7h18.8c8.9 0 13.7 6.3 13.7 13.7 0 5.9-3.3 10.5-8.7 12.2zm-6.2-7.1c2.8 0 4.5-2.1 4.5-5 0-2.9-1.7-5.1-4.5-5.1h-7.3v10.1z"
            />
            <path
              fill={fill}
              d="m324.2 166.1v8.5h-11.3v32.2h-10.3v-32.2h-11.1v-8.5z"
            />
            <path
              fill={fill}
              d="m339.8 184.2v22.6h-10.3v-40.7h7.9l16.2 22.3v-22.3h10.3v40.7h-7.9z"
            />
            <path
              fill={fill}
              d="m373.2 206.8v-40.7h28v8.5h-17.7v8.1h15.4v8h-15.4v7.6h17.7v8.5z"
            />
            <path
              fill={fill}
              d="m433 192c0.8 4.1 2.7 6.9 6 6.9 1.4 0 3.1-0.6 4.3-1.6l3.4 6.8c-2.8 2.1-5.5 3.4-9.7 3.4-10 0-13-10.6-13.6-14.8h-3.9v14.1h-10.3v-40.7h18.8c8.9 0 13.7 6.3 13.7 13.7 0 5.9-3.3 10.5-8.7 12.2zm-6.2-7.1c2.8 0 4.5-2.1 4.5-5 0-2.9-1.7-5.1-4.5-5.1h-7.3v10.1z"
            />
            <path
              fill={fill}
              d="m457 193.3c1 3.7 3.4 5.7 7.3 5.7 3.1 0 5.6-1.1 5.6-3.7 0-1.3-0.7-2.7-4.2-3.9l-4.5-1.6c-6.2-2.1-11.1-6.5-11.1-12.1 0-7.5 6.2-12.3 14.6-12.3 6.8 0 11.7 3.4 14.1 9.6l-8.3 3.4c-1.4-3.5-3.5-4.5-5.8-4.5-2.4 0-4.4 1-4.4 3.4 0 1.1 0.4 2.6 3.9 3.8l4.4 1.5c9.1 3 11.3 7.9 11.5 12.4 0 8.6-7.7 12.5-15.6 12.5-8.4 0-15.2-4.5-16.2-12.3z"
            />
            <path
              fill={fill}
              d="m511.3 206.8v-15.3h-14v15.3h-10.3v-40.7h10.3v17.1h14v-17.1h10.3v40.7z"
            />
            <path fill={fill} d="m541.2 206.8h-10.3v-40.7h10.3z" />
            <path
              fill={fill}
              d="m550.3 206.8v-40.7h18.7c8.8 0 13.7 6.4 13.7 13.8 0 7.4-4.9 13.4-13.7 13.4h-8.4v13.5zm17.5-21.7c2.7 0 4.5-2.3 4.5-5.2 0-3-1.8-5.3-4.5-5.3h-7.2v10.5z"
            />
            <path
              fill={fill}
              d="m638.4 189.2l6.7-23.1h10.6l-12.9 40.7h-8l-7-22.7-7 22.7h-8l-12.8-40.7h10.5l6.8 23.1 6.9-21.8h7.4z"
            />
            <path fill={fill} d="m671.2 206.8h-10.3v-40.7h10.3z" />
            <path
              fill={fill}
              d="m709.1 166.1v8.5h-11.3v32.2h-10.3v-32.2h-11.1v-8.5z"
            />
            <path
              fill={fill}
              d="m738.8 206.8v-15.3h-14.1v15.3h-10.3v-40.7h10.3v17.1h14.1v-17.1h10.3v40.7z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

DoctorsOnDemandLogo.displayName = displayName;
DoctorsOnDemandLogo.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
DoctorsOnDemandLogo.defaultProps = {
  id: "image.e2.doctorsondemand.logo",
  size: 24,
};

export default DoctorsOnDemandLogo;
