import * as codeType from "constants/generateCodeTypes";
import SecondLogo from "./SecondLogo";
import englishOverrides from "./translations/en.json";
import malaysianOverrides from "./translations/ms.json";
import vietnameseOverrides from "./translations/vi.json";
import thaiOverrides from "./translations/th.json";
import indonesianOverrides from "./translations/id.json";
import chineseTraditionalOverrides from "./translations/zh-Hant.json";
import cantoneseOverrides from "./translations/zh-Yue.json";
import ukrainianOverrides from "./translations/uk.json";
import arOverrides from "./translations/ar.json";
// NEWLANGUAGE ADD ABOVE import all languages for Mastercard

const config = {
  general: {
    partnerOverride: "EMMA2_MASTERCARD01_PARTNER",
    partnerGroup: "mastercard",
    partnerCountry: "None",
    partnerCode: "mydoc",
    subPartnerCode: "allianzemma",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    isDemoSite: true,
    secondLogo: SecondLogo,
    DHA: true,
    MyDoc: true,
    Halodoc: true,
    DoctorAnywhere: true,
    CareHQ: true,
    DoctorsOnDemand: true,
    // DHA: false,
    // Teleconsultation: true,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null,
    showLegalInfo: true,
    showMentalHealth: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  // languages: "en ar ms vi th uk id zh-Hant yue".split(" "),
  messages: {
    en: englishOverrides,
    ms: malaysianOverrides, // Bahasa Melayu
    vi: vietnameseOverrides,
    th: thaiOverrides,
    id: indonesianOverrides, // Bahasa Indonesia
    "zh-Hant": chineseTraditionalOverrides,
    yue: cantoneseOverrides,
    uk: ukrainianOverrides,
    ar: arOverrides,
    // NEWLANGUAGE ADD ABOVE access to all languages for Mastercard
  },
};

config.languages = Object.keys(config.messages).sort();

export default config;
