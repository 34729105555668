import * as codeType from "constants/generateCodeTypes";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 mc-core-au
// inherit-partner.sh mastercard subpartner mc-core-au 2 Australia digitalhealthxtra au allianz mydoc MCCOREAU en
// DEVTEST Test Australia with Mastercard: 2020202023
const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_AU_2",
    partnerGroup: "mastercard",
    partnerCountry: "Australia",
    partnerCode: "mydoc",
    subPartnerCode: "MCCOREAU",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    DHA: true,
    MyDoc: false,
    showLegalInfo: true,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null,
    showInfermedicaLogo: true,
    DoctorsOnDemand: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "en".split(" "),
  messages: {
    en: enOverrides,
  },
};

export default config;
