import * as codeType from "constants/generateCodeTypes";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-core-sg mc-zen 45 mastercard Europe digitalhealthxtra zn allianz MC_ZEN NONE en

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_ZN_45",
    partnerGroup: "mastercard",
    partnerCountry: "Europe",
    partnerCode: "MC_ZEN",
    subPartnerCode: "",
    generalEmail: "datenschutz-azpde@allianz.com",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    title: ""
  },
  features: {
    showEmma2: true,
    secondLogo: null,
    allowPtUrl: false,
    DHA: true,
    noMedicalHotline: true,
    showLegalInfo: true, // true when MyDoc is false and legal info needs to show
    showViber: false,
    showLineApp: false,
    hideTelegram: false,
    generateStepType: codeType.BIN_ONLY,
    // policyNumberRegex: /^.{6,}$/,
    // binOnlyRegex: /^.{6,}$/,
    // binOnlyPassword: true,
    helplineEmail: null, // suppresses display of email on error step - delete to show from value in general section
    // helplinePhoneNumber: +43 5 9009 588,
    showInfermedicaLogo: true,
    hideMayoClinic: true,
    hideSensely:true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54"
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02"
    }
  }, // footer

  languages: "en".split(" "),
  messages: {
    "en": enOverrides
  }
};

export default config;

