import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 azp-newzealand
// inherit-partner.sh mc-mena-kw subpartner azp-newzealand 27 New Zealand digitalhealthservice azpnz allianz AZPNZ_UPEDIS,AZPNZ_UPEDDS,AZPNZ_UPEDEM,AZPNZ_WINTECIS,AZPNZ_WINTECDS,AZPNZ_WINTECEM AZPNZ en

const config = {
  general: {
    partnerOverride: "DIGITALHEALTHSERVICE_AZPNZ_27",
    partnerGroup: "allianz",
    partnerCountry: "New Zealand",
    partnerCode: "AZPNZ_STUDENTSAFE",
    subPartnerCode: "AZPNZ",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    generalEmail: "digitalhealth@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    MyDoc: false,
    QuickSummary: null,
    showLegalInfo: false, // true when MyDoc is false and legal info needs to show
    showLineApp: true,
    hideWhatsApp: true,
    generateStepType: undefined,
    noMedicalHotline: true,
    showMentalHealth: true,
    secondLogo: null,
    helplineEmail: null,
    showInfermedicaLogo: true,
    CareHQ: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
