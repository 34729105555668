import PropTypes from "prop-types";
import ErrorBox from "components/e2/ErrorBox";
import {
  INVALID_CREDENTIALS,
  MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED,
  GENERIC_ERROR,
  INVITE_ERROR,
} from "api/errorCodes";

const displayName = "FTUErrorMessage";

function FTUErrorMessage({ errorCode }) {
  function renderContent() {
    let id;
    let isRenderHelplineInfo = false;
    // While using REACT_APP_API_DEBUG=simulate,
    // the errorcode MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED is mocked through cypress
    // Common error for wrong values entered on front-end = INVALID_CREDENTIALS e.g Policy number = A401234567 YoB = 2080
    // For MyDoc/Halodoc/DoctorAnywhere/CareHQ/DoctorsOnDemand invitation url failure = INVITE_ERROR
    // Default = GENERIC_ERROR
    switch (errorCode) {
      case MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED:
        id = "e2.eligibilityCheck.verifyFirst.maxNumberOfCodesError.title";
        isRenderHelplineInfo = true;
        break;
      case INVALID_CREDENTIALS:
        id = "e2.eligibilityCheck.verifyFirst.invalidCredentialsError.title";
        isRenderHelplineInfo = true;
        break;
      case INVITE_ERROR:
        id = "e2.eligibilityCheck.verifyFirst.inviteError.title";
        break;
      case GENERIC_ERROR:
      default:
        id = "e2.eligibilityCheck.verifyFirst.genericError.title";
        break;
    }
    return <ErrorBox id={id} isRenderHelplineInfo={isRenderHelplineInfo} />;
  }

  return renderContent();
}

FTUErrorMessage.displayName = displayName;

FTUErrorMessage.propTypes = {
  errorCode: PropTypes.string.isRequired,
};
FTUErrorMessage.defaultProps = {};

export default FTUErrorMessage;
