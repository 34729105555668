// created by templates/mk-icon.sh
import PropTypes from "prop-types";
import { colorIcon } from "@medi24-da2c/web-ui/emma2";
import { OptionalTitle } from "components/OptionalMessage";

const fill = colorIcon;

const displayName = "CareHQLogo";

const HEIGHT = 24;

function CareHQLogo({ id, ...props }) {
  // Picking good alt text for accessibility.
  // https://webaim.org/techniques/alttext/

  // SVG editor for rescaling the viewbox
  //  https://pixelied.com/editor

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 125 24`}
      height={HEIGHT}
      role="img"
      aria-labelledby="icon-CareHQLogo-title"
      data-testid={`icon-${displayName}`}
      {...props}
    >
      <OptionalTitle id={id} titleId="icon-CareHQLogo-title" />
      <g
        id="icon-CareHQLogo-symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          fill={fill}
          d="M0 11.705C0 5.169 4.749.532 11.25.532c4.352 0 6.988 1.898 8.396 3.84l-3.65 3.445c-1.011-1.503-2.551-2.297-4.484-2.297-3.385 0-5.76 2.474-5.76 6.18 0 3.708 2.375 6.226 5.76 6.226 1.933 0 3.473-.882 4.484-2.34l3.65 3.444c-1.408 1.942-4.044 3.884-8.395 3.884C4.749 22.918 0 18.284 0 11.704zM34.722 22.39v-2.208c-1.451 1.72-3.954 2.736-6.726 2.736-3.385 0-7.384-2.296-7.384-7.063 0-4.989 4-6.89 7.384-6.89 2.812 0 5.319.927 6.726 2.607v-2.87c0-2.163-1.845-3.577-4.66-3.577-2.286 0-4.396.838-6.197 2.518l-2.11-3.751C24.35 1.595 27.687.535 31.03.535c4.833 0 9.273 1.943 9.273 8.08V22.39h-5.58zm0-5.121v-2.647c-.922-1.237-2.679-1.854-4.484-1.854-2.198 0-3.999 1.148-3.999 3.223 0 1.987 1.801 3.135 4 3.135 1.8-.004 3.56-.624 4.483-1.857zM43.202 22.39V1.063h5.583v2.87c1.54-1.854 4.131-3.401 6.77-3.401v5.475c-.397-.088-.878-.133-1.54-.133-1.845 0-4.308 1.06-5.23 2.43V22.39h-5.583zM55.728 11.705c0-6.181 4.572-11.17 10.99-11.17 6.373 0 10.636 4.767 10.636 11.701v1.326H61.576c.396 2.607 2.506 4.77 6.108 4.77 1.801 0 4.264-.75 5.627-2.075l2.507 3.707c-2.11 1.942-5.45 2.958-8.748 2.958-6.465-.004-11.342-4.376-11.342-11.217zm10.99-6.58c-3.474 0-4.966 2.43-5.187 4.549h10.46c-.176-2.03-1.583-4.55-5.273-4.55zM94.067 22.39L94.067 13.104 83.706 13.104 83.706 22.386 79.034 22.386 79.034.354 83.706.354 83.706 9.009 94.067 9.009 94.067.354 98.768.354 98.768 22.39zM119.67 14.171h-4.686v4.708h-5.642V14.17h-4.686V8.503h4.686V3.796h5.642v4.707h4.686v5.668zm3.959-2.895C123.629 5 119.097 0 112.16 0c-6.858 0-11.449 5.095-11.449 11.372s4.381 11.413 11.446 11.413c2.113 0 3.48-.243 5.182-1.107l1.783 2.075 3.27-2.692-1.602-1.861c1.842-2.22 2.842-4.697 2.842-7.883v-.04h-.004z"
        />
      </g>
    </svg>
  );
}

CareHQLogo.displayName = displayName;
CareHQLogo.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
CareHQLogo.defaultProps = {
  id: "image.e2.carehq.logo",
  size: 24,
};

export default CareHQLogo;
