import { getInstance } from "partnerConfigs/singleton";
import useHalodoc from "./useHalodoc";
import useMyDoc from "./useMyDoc";
import useDoctorAnywhere from "./useDoctorAnywhere";
import useCareHQ from "./useCareHQ";
import useDoctorsOnDemand from "./useDoctorsOnDemand";

function useTeleconsultation(force) {
  const hasMyDoc = useMyDoc();
  const hasHalodoc = useHalodoc();
  const hasDoctorAnywhere = useDoctorAnywhere();
  const hasCareHQ = useCareHQ();
  const hasDoctorsOnDemand = useDoctorsOnDemand();
  const { config } = getInstance();

  if (typeof force === "boolean") {
    return force;
  }
  const hasTeleconsultation = !!(
    config.features && config.features.Teleconsultation
  );
  return (
    hasTeleconsultation ||
    hasMyDoc ||
    hasHalodoc ||
    hasDoctorAnywhere ||
    hasCareHQ ||
    hasDoctorsOnDemand
  );
}

export default useTeleconsultation;
